<!--消费模块--消费设置-->
<template>
  <div
    id="consumptionSetting"
    v-loading="loading"
    :element-loading-text="$t('consume.a1')"
  >
    <div class="header">
      <my-headertitle>{{ $t("consume.a62") }}</my-headertitle>
    </div>
    <div class="main">
      <div class="consumeTimeSet">
        <my-tabletitle>{{ $t("consume.a63") }}</my-tabletitle>
        <div class="row">
          <div>
            <span>{{ $t("consume.a9") }}</span>
            <a-time-picker
              :allowClear="false"
              v-model="breakfast_begin"
              format="HH:mm"
            />
            <span class="interim">~</span>
            <a-time-picker
              :allowClear="false"
              v-model="breakfast_end"
              format="HH:mm"
            />
          </div>
          <div>
            <span>{{ $t("consume.a10") }}</span>
            <a-time-picker
              :allowClear="false"
              v-model="lunch_begin"
              format="HH:mm"
            />
            <span class="interim">~</span>
            <a-time-picker
              :allowClear="false"
              v-model="lunch_end"
              format="HH:mm"
            />
          </div>
        </div>
        <div class="row">
          <div>
            <span>{{ $t("consume.a11") }}</span>
            <a-time-picker
              :allowClear="false"
              v-model="supper_begin"
              format="HH:mm"
            />
            <span class="interim">~</span>
            <a-time-picker
              :allowClear="false"
              v-model="supper_end"
              format="HH:mm"
            />
          </div>
          <div>
            <span>{{ $t("consume.a12") }}</span>
            <a-time-picker
              :allowClear="false"
              v-model="night_begin"
              format="HH:mm"
            />
            <span class="interim">~</span>
            <a-time-picker
              :allowClear="false"
              v-model="night_end"
              format="HH:mm"
            />
          </div>
        </div>
      </div>
      <div class="consumeTimeSet">
        <my-tabletitle>{{ $t("consume.a64") }}</my-tabletitle>
        <div class="rowset">
          <div class="rowset_grid">
            <p>{{ $t("consume.a65") }}</p>
            <a-input
              :placeholder="$t('consume.a65')"
              allow-clear
              v-model="intervalTime"
              style="width: 150px"
              v-pureNumber
            />
          </div>
          <div class="rowset_grid">
            <p>{{ $t("consume.a66") }}</p>
            <a-input
              :placeholder="$t('consume.a66')"
              allow-clear
              v-model="dayConsumeLimit"
              style="width: 150px"
              v-pureNumber
            />
          </div>
          <div class="rowset_grid">
            <p>{{ $t("consume.a67") }}</p>
            <a-input
              :placeholder="$t('consume.a6')"
              allow-clear
              v-model="countLimit"
              style="width: 150px"
              v-pureNumber
            />
          </div>
          <div class="rowset_grid">
            <p>{{ $t("consume.a68") }}</p>
            <a-select v-model="deviceSound" style="width: 150px">
              <a-select-option :value="0">
                {{ $t("consume.a69") }}
              </a-select-option>
              <a-select-option :value="1">
                {{ $t("consume.a70") }}
              </a-select-option>
              <a-select-option :value="2">
                {{ $t("consume.a71") }}
              </a-select-option>
              <a-select-option :value="3">
                {{ $t("consume.a72") }}
              </a-select-option>
              <a-select-option :value="4">
                {{ $t("consume.a73") }}
              </a-select-option>
              <a-select-option :value="5">
                {{ $t("consume.a74") }}
              </a-select-option>
            </a-select>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <a-button type="primary" @click="setConsumeParameter">
        {{ $t("consume.a75") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle";
import tabletitle from "../../components/Title/tabletitle";
import moment from "moment";
import { getConsumeDeviceInfo, setConsumeDeviceInfo } from "../../api/consume";

export default {
  name: "consumptionSetting",
  data() {
    return {
      breakfast_begin: moment(),
      breakfast_end: moment(),
      lunch_begin: moment(),
      lunch_end: moment(),
      supper_begin: moment(),
      supper_end: moment(),
      night_begin: moment(),
      night_end: moment(),
      intervalTime: "",
      dayConsumeLimit: "",
      countLimit: "",
      deviceSound: 1,
      loading: false,
    };
  },
  // 获取设置参数
  mounted() {
    this.loading = true;
    let data = {
      siteId: this.$route.query.id,
    };
    getConsumeDeviceInfo(data)
      .then((res) => {
        // console.log(res);
        this.loading = false;
        const { data } = res;
        const { dinnerInformations: list } = data;
        this.intervalTime = data.intervalTime;
        this.dayConsumeLimit = data.dayConsumeLimit;
        this.countLimit = data.countLimit;
        this.deviceSound = data.deviceSound;
        if (list == null) {
          this.breakfast_begin = moment("00:00", "HH:mm");
          this.breakfast_end = moment("00:00", "HH:mm");
          this.lunch_begin = moment("00:00", "HH:mm");
          this.lunch_end = moment("00:00", "HH:mm");
          this.supper_begin = moment("00:00", "HH:mm");
          this.supper_end = moment("00:00", "HH:mm");
          this.night_begin = moment("00:00", "HH:mm");
          this.night_end = moment("00:00", "HH:mm");
        } else {
          for (let i = 0; i < list.length; i++) {
            let value = list[i].mealTimes;
            switch (value) {
              case 1:
                this.breakfast_begin = moment(list[i].startTime, "HH:mm");
                this.breakfast_end = moment(list[i].endTime, "HH:mm");
                break;
              case 2:
                this.lunch_begin = moment(list[i].startTime, "HH:mm");
                this.lunch_end = moment(list[i].endTime, "HH:mm");
                break;
              case 3:
                this.supper_begin = moment(list[i].startTime, "HH:mm");
                this.supper_end = moment(list[i].endTime, "HH:mm");
                break;
              case 4:
                this.night_begin = moment(list[i].startTime, "HH:mm");
                this.night_end = moment(list[i].endTime, "HH:mm");
                break;
              default:
                return false;
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  methods: {
    moment,
    // 设置消费参数
    setConsumeParameter() {
      this.$confirm({
        title: this.$t("consume.a76"),
        content: this.$t("consume.a77"),
        centered: true,
        onOk: () => {
          this.loading = true;
          const body = {
            intervalTime: this.intervalTime,
            countLimit: this.countLimit,
            deviceSound: this.deviceSound,
            dayConsumeLimit: this.dayConsumeLimit,
            dinnerInformations: [
              {
                mealTimes: 1,
                startTime: this.breakfast_begin.format("HH:mm"),
                endTime: this.breakfast_end.format("HH:mm"),
              },
              {
                mealTimes: 2,
                startTime: this.lunch_begin.format("HH:mm"),
                endTime: this.lunch_end.format("HH:mm"),
              },
              {
                mealTimes: 3,
                startTime: this.supper_begin.format("HH:mm"),
                endTime: this.supper_end.format("HH:mm"),
              },
              {
                mealTimes: 4,
                startTime: this.night_begin.format("HH:mm"),
                endTime: this.night_end.format("HH:mm"),
              },
            ],
          };
          const query = {
            siteId: this.$route.query.id,
          };
          // console.log(body, query);
          setConsumeDeviceInfo(body, query)
            .then((res) => {
              // console.log(res);
              this.loading = false;
              if (res.errorCode == "00") {
                this.$message.success(res.msg);
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        },
      });
    },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
#consumptionSetting {
  height: 100%;
}
p {
  margin-bottom: 7px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.header {
  padding: 0px 20px;
}
.main {
  height: calc(100% - 103px);
  padding: 20px 20px 0px 20px;
  overflow: auto;
}
.consumeTimeSet {
  border: 1px solid #f3f3f5;
  padding-bottom: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}
.row {
  display: flex;
  margin-top: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
}
.row div {
  margin-right: 20px;
  padding-left: 20px;
}
.row span {
  margin-right: 15px;
}
.interim {
  font-size: 20px;
  margin: 0px;
}
.rowset {
  display: flex;
  padding-left: 20px;
  margin-top: 20px;
  white-space: nowrap;
}
.rowset_grid {
  margin-right: 30px;
}
.footer {
  display: flex;
  justify-content: flex-end;
  height: 52px;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px #bdbcbc;
}
</style>